import order from "@/pages/Orders/List/Order";

/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
const ROUTES_FILTER: { [key: string]: string[] } = {
  super_admin: [
    'welcome', 'desktop', 'orders', 'order', 'masters', 'master', 'new-master', 'master-invite',
    'masters-priorities', 'masters-priorities-new', 'customers', 'customer',
    'lists', 'lists.brands', 'lists.branches', 'lists.sites', 'lists.appliances',
    'lists.phones', 'lists.phone_groups', 'lists.users', 'profile', 'planning',
    'reports', 'reports.debts', 'reports.appliances', 'reports.branches', 'reports.masters',
    'finance', 'finance-show'
  ],

  admin: [
    'welcome', 'desktop', 'orders', 'order', 'masters', 'master', 'new-master', 'master-invite',
    'masters-priorities', 'masters-priorities-new', 'customers', 'customer',
    'lists', 'lists.brands', 'profile', 'planning', 'reports',
    'reports.debts'
  ],
  senior_manager: [
    'welcome', 'orders', 'order', 'masters', 'master', 'new-master', 'profile'
  ],
  manager: [
    'welcome', 'orders', 'order', 'masters', 'master', 'profile'
  ],

  accountant: [
    'welcome', 'orders', 'order', 'customers', 'customer', 'masters', 'master', 'profile'
  ],

  hr: [
    'welcome', 'masters', 'master', 'profile'
  ],

  operator: [
    'welcome', 'desktop', 'orders', 'order', 'profile'
  ],
  senior_master: [
    'welcome', 'desktop-master', 'my-working-time', 'orders', 'order', 'masters', 'master', 'new-master', 'master-invite', 'planning', 'profile'
  ],
  master: [
    'welcome', 'orders', 'order', 'my-working-time', 'profile', 'desktop-master'
  ],
};

export default function access(initialState: { currentUser?: API.CurrentUser } | undefined) {
  const { currentUser } = initialState ?? {};
  const { role } = currentUser || { role: '' };
  return {
    isSuperAdmin: role === 'super_admin',
    isAdmin: role === 'admin',
    isManager: role === 'manager',
    isOperator: role === 'operator',
    isMaster: role === 'master',
    isAccountant: role === 'accountant',
    isHr: role === 'hr',
    isSeniorMaster: role === 'senior_master',

    isExtendedAccess: ['super_admin', 'admin', 'accountant', 'manager', 'senior_manager', 'senior_master'].includes(role),

    canCreateOrder: ['super_admin', 'admin', 'manager', 'senior_manager', 'accountant', 'operator', 'senior_master'].includes(role),
    canViewOrderHistories: ['super_admin', 'admin', 'manager', 'senior_manager', 'senior_master', 'accountant', 'operator'].includes(role),
    canOpenCustomer: ['super_admin', 'admin', 'manager', 'senior_manager', 'senior_master', 'accountant'].includes(role),

    canManageMaster: ['super_admin', 'admin', 'senior_manager', 'senior_master'].includes(role),

    routesFilter: (route: any) => {
      if (!currentUser || !currentUser.role) {
        return false;
      }

      return (ROUTES_FILTER[currentUser.role] || []).includes(route.name);
    },
  };
}
